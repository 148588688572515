export const TEXAS_GAS_TRANSMISSION_CODE = 100000;
export const GULF_SOUTH_TRANSMISSION_CODE = 1;
export const BOARDWALK_STORAGE_TRANSMISSION_CODE = 6;
export const BLGT_TRANSMISSION_CODE = 8;

export const tspShortNameMap = {
  TEXAS_GAS: 'Texas Gas',
  GULF_SOUTH: 'Gulf South',
  BOARDWALK_STORAGE: 'Boardwalk Storage',
  BLGT: 'Boardwalk Louisiana Gas Transmission',
  BLM: 'BLM'
};

export const blmTSPId = [9];