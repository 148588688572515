import { Action } from '@ngrx/store';
import { PostingParamsModel, PostingResponseModel } from './posting-response.model';
import { InfoPostMenuItems } from "@gms/reporting-api/model/infoPostMenuItems";
import { InfoPostMenuRequest } from 'app/modules/administration/pages/posting/create-posting/create-posting/create-posting.utils';

export enum EPostingsActions {
  FETCH_POSTING_LIST = '[POSTING] Fetch Posting list',
  FETCH_POSTING_LIST_SUCCESS = '[POSTING] Fetch Posting list Success',
  FETCH_POSTING_LIST_ERROR = '[POSTING] Fetch Posting list Failure',

  FETCH_POSTING_LIST_BY_ID = '[POSTING] Fetch Posting Data By ID',
  FETCH_POSTING_LIST_BY_ID_SUCCESS = '[POSTING] Fetch Posting Data By ID Success',
  FETCH_POSTING_LIST_BY_ID_FAILURE = '[POSTING] Fetch Posting Data By ID Failure',

  CREATE_NEW_POSTING = '[POSTING] Create New Posting',
  RESET_NEW_POSTING = '[POSTING] Reset New Posting',
  CREATE_NEW_POSTING_SUCCESS = '[POSTING] Create New Posting Success',
  CREATE_NEW_POSTING_FAILURE = '[POSTING] Create New Posting Failure',

  DELETE_POSTING = '[POSTING] Delete Posting',
  DELETE_POSTING_SUCCESS = '[POSTING] Delete Posting success',
  DELETE_POSTING_FAILURE = '[POSTING] Delete Posting failure',

  FETCH_MENU_ITEM_LIST = '[POSTING] Fetch Menu Item List',
  FETCH_MENU_ITEM_LIST_SUCCESS = '[POSTING] Fetch Menu Item List success',
  FETCH_MENU_ITEM_LIST_FAILURE = '[POSTING] Fetch Menu Item List failure',


  POSTING_FILTER_LIST = '[POSTING] Post Posting Filter Data',
  FETCH_POSTING_FILTER_LIST = '[POSTING] Fetch Posting Filter Data',
  UPDATE_POSTING_FILTER_FLAG = '[POSTING] Update Posting Filter Data Flag',

  UPLOAD_TARIFF_POSTING = '[POSTING] Upload tariffs',
  RESET_TARIFF_POSTING = '[POSTING] Reset tariffs',
  UPLOAD_TARIFF_POSTING_SUCCESS = '[POSTING] Upload tariffs success',
  UPLOAD_TARIFF_POSTING_FAILURE = '[POSTING] Upload tariffs failure',
  SET_UPLOAD_TARIFF_POSTING_LOADING = '[POSTING] Set upload tariffs loading',

  DOWNLOAD_POSTING_DOCUMENT = '[DOCUMENT] Download Posting Document',
  DOWNLOAD_POSTING_DOCUMENT_SUCCESS = '[DOCUMENT] Download Posting Document success',
  DOWNLOAD_POSTING_DOCUMENT_FAILURE = '[DOCUMENT] Download Posting Document failure',
}

export interface IUploadPostingPayload {
  tspId: number;
  description: string;
  menuItem: number;
  active: boolean;
  datetimePostingEffective: any;
  datetimePostingExpire: any;
  document?: File;
  alternateDocument: File;
  contractId: string;
  postImmediately: boolean;
  displayOrder?: number;
  updateDateTimePostingEffective: boolean;
  additionalProperties?: string;
  userId: number;
  isLargeFileUploaded: boolean;
}

export interface PostingDetailsPayload {
  tsp_id: number,
  infopost_id: number,
  date: Date
}

export interface ITariffUploadPayload {
  fileName: string;
  file: File;
  contentType: string;
  contentLength?: any;
  groupCode:string;
}

export interface ITariffUploadResponse {
  success: boolean;
}

export class LoadPostingListRequestAction implements Action {
  public readonly type = EPostingsActions.FETCH_POSTING_LIST;

  constructor(public payload: PostingParamsModel) { }
}

export class LoadPostingListSuccessAction implements Action {
  public readonly type = EPostingsActions.FETCH_POSTING_LIST_SUCCESS;

  constructor(public payload: PostingResponseModel[]) { }
}
export class LoadPostingListFailureAction implements Action {
  public readonly type = EPostingsActions.FETCH_POSTING_LIST_ERROR;

  constructor(public payload: { error: Error }) { }
}

export class GetPostingDetailsById implements Action {
  public readonly type = EPostingsActions.FETCH_POSTING_LIST_BY_ID;

  constructor(public payload: PostingDetailsPayload, public groupCode: string = "INFOPOST") { }
}

export class GetPostingDetailsByIdSuccess implements Action {
  public readonly type = EPostingsActions.FETCH_POSTING_LIST_BY_ID_SUCCESS;

  constructor(public payload: PostingResponseModel) { }
}

export class GetPostingDetailsByIdFailure implements Action {
  public readonly type = EPostingsActions.FETCH_POSTING_LIST_BY_ID_FAILURE;

  constructor(public payload: { error: Error }) { }
}

export class CreatePostingAction implements Action {
  public readonly type = EPostingsActions.CREATE_NEW_POSTING;

  constructor(public payload: IUploadPostingPayload, public groupCode = "INFOPOST") { }
}
export class CreatePostingSuccessAction implements Action {
  public readonly type = EPostingsActions.CREATE_NEW_POSTING_SUCCESS;

  constructor(public payload: PostingResponseModel[]) { }
}

export class CreatePostingFailureAction implements Action {
  public readonly type = EPostingsActions.CREATE_NEW_POSTING_FAILURE;

  constructor(public payload: { error: Error }) { }
}

export class ResetPostingSuccessAction implements Action {
  public readonly type = EPostingsActions.RESET_NEW_POSTING;

  constructor() { }
}

export class DeletePostingAction implements Action {
  public readonly type = EPostingsActions.DELETE_POSTING;

  constructor(public payload: PostingDetailsPayload, public groupCode: string = 'INFOPOST') { }
}
export class DeletePostingActionSuccess implements Action {
  public readonly type = EPostingsActions.DELETE_POSTING_SUCCESS;

  constructor(public payload: boolean) { }
}
export class DeletePostingActionFailure implements Action {
  public readonly type = EPostingsActions.DELETE_POSTING_FAILURE;

  constructor(public payload: { error: Error }) { }
}

export class FetchPostingMenuItemsAction implements Action {
  public readonly type = EPostingsActions.FETCH_MENU_ITEM_LIST;

  constructor(public info: InfoPostMenuRequest) { }
}
export class FetchPostingMenuItemsSuccess implements Action {
  public readonly type = EPostingsActions.FETCH_MENU_ITEM_LIST_SUCCESS;

  constructor(public payload: InfoPostMenuItems) { }
}
export class FetchPostingMenuItemsFailure implements Action {
  public readonly type = EPostingsActions.FETCH_MENU_ITEM_LIST_FAILURE;

  constructor(public payload: { error: Error }) { }
}

export class FetchPostingFilterData implements Action {
  public readonly type = EPostingsActions.FETCH_POSTING_FILTER_LIST;

  constructor() { }
}

export class PostFilterData implements Action {
  public readonly type = EPostingsActions.POSTING_FILTER_LIST;

  constructor(public payload: PostingParamsModel) { }
}

export class UpdatePostFilterDataFlag implements Action {
  public readonly type = EPostingsActions.UPDATE_POSTING_FILTER_FLAG;

  constructor(public istriggerSearchApi: boolean) { }
}

export class UploadTariffs implements Action {
  public readonly type = EPostingsActions.UPLOAD_TARIFF_POSTING;

  constructor(public payload: ITariffUploadPayload) { }
}

export class UploadTariffsSuccess implements Action {
  public readonly type = EPostingsActions.UPLOAD_TARIFF_POSTING_SUCCESS;

  constructor(public payload: ITariffUploadResponse) { }
}

export class UploadTariffsError implements Action {
  public readonly type = EPostingsActions.UPLOAD_TARIFF_POSTING_FAILURE;

  constructor(public payload: { error: Error }) { }
}

export class ResetTariffsSuccessAction implements Action {
  public readonly type = EPostingsActions.RESET_TARIFF_POSTING;

  constructor() { }
}

export class SetUploadTariffsLoading implements Action {
  public readonly type = EPostingsActions.SET_UPLOAD_TARIFF_POSTING_LOADING;

  constructor(public payload: { loading: boolean }) { }
}

export class DownloadPostingDocumentAction implements Action {
  public readonly type = EPostingsActions.DOWNLOAD_POSTING_DOCUMENT;

  constructor(public infopostTrackID: number) { }
}

export class DownloadPostingDocumentActionSuccess implements Action {
  public readonly type = EPostingsActions.DOWNLOAD_POSTING_DOCUMENT_SUCCESS;

  constructor() { }
}

export class DownloadPostingDocumentActionFailure implements Action {
  public readonly type = EPostingsActions.DOWNLOAD_POSTING_DOCUMENT_FAILURE;

  constructor(public payload: { error: Error }) { }
}

export type PostingsActions =
  | LoadPostingListRequestAction
  | LoadPostingListSuccessAction
  | LoadPostingListFailureAction
  | GetPostingDetailsById
  | GetPostingDetailsByIdSuccess
  | GetPostingDetailsByIdFailure
  | DeletePostingAction
  | DeletePostingActionSuccess
  | DeletePostingActionFailure
  | CreatePostingAction
  | CreatePostingSuccessAction
  | CreatePostingFailureAction
  | FetchPostingMenuItemsAction
  | FetchPostingMenuItemsSuccess
  | FetchPostingMenuItemsFailure
  | FetchPostingFilterData
  | PostFilterData
  | UpdatePostFilterDataFlag
  | UploadTariffs
  | UploadTariffsSuccess
  | UploadTariffsError
  | SetUploadTariffsLoading
  | ResetPostingSuccessAction
  | ResetTariffsSuccessAction
  | DownloadPostingDocumentAction
  | DownloadPostingDocumentActionSuccess
  | DownloadPostingDocumentActionFailure;
