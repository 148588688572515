import { ETspActions, IFetchTspPayload, IFetchTspsPayload, TspsActions } from './tsps.actions';

//need to use the function format for AOT
import {
  createCacheableSuccessState,
  createErrorState,
  createLoadingState,
  createSuccessState,
  ICachedDataState,
  useCache,
} from 'app/store/app/app.models';
import { initialTspsState, ITspState } from './tsps.state';
import { ServiceProvider } from '@gms/tsp-api';
import { TSPConstant } from 'shared/utils/tsp.utils';

export function tspsReducers(state = initialTspsState, action: TspsActions): ITspState {
  switch (action.type) {
    case ETspActions.FetchTsps:
      if (useCache(state.serviceProviders, action)) return state;

      return {
        ...state,
        serviceProviders: createLoadingState(state.serviceProviders),
      };
    case ETspActions.FetchTspsSuccess:
      return {
        ...state,
        serviceProviders: excludeSuppressTSP(action.payload, action.requestPayload),
        peopleServiceProviders: createCacheableSuccessState(action.payload, action.requestPayload)
      };
    case ETspActions.FetchTspsError:
      return {
        ...state,
        serviceProviders: createErrorState(action.error),
        peopleServiceProviders: createErrorState(action.error),
      };
    case ETspActions.GetTspByIdSuccess:
      return {
        ...state,
        serviceProvider: action.payload,
        loading: false,
        error: false,
      };
    case ETspActions.GetTspByIdError:
      return {
        ...state,
        serviceProvider: action.error,
        error: true,
        loading: false,
      };
    case ETspActions.GetTspById:
      return {
        ...state,
        loading: true,
      };
    case ETspActions.GetTspHolidays:
      return {
        ...state,
        tspHolidays: {
          ...state.tspHolidays,
          requestStatus: {
            loading: true,
            httpError: null,
          },
        },
      };
    case ETspActions.GetTspHolidaysSuccess:
      return {
        ...state,
        tspHolidays: {
          ...state.tspHolidays,
          data: action.payload,
          requestStatus: {
            loading: false,
            httpError: null,
          },
        },
      };
    case ETspActions.GetTspHolidaysError:
      return {
        ...state,
        tspHolidays: {
          data: { tspHolidays: [] },
          requestStatus: {
            loading: false,
            httpError: action.payload,
          },
        },
      };
    case ETspActions.GetAllTspHolidays:
      return {
        ...state,
        tspHolidayCollections: createLoadingState(state.tspHolidayCollections),
      };
    case ETspActions.GetAllTspHolidaysSuccess:
      return {
        ...state,
        tspHolidayCollections: createSuccessState(action.payload),
      };
    case ETspActions.GetAllTspHolidaysError:
      return {
        ...state,
        tspHolidayCollections: createErrorState(action.payload),
      };
    default:
      return state;
  }
}

function excludeSuppressTSP(payload: ServiceProvider[], requestPayload: IFetchTspsPayload) {
  let tspProviders = createCacheableSuccessState(payload, requestPayload)
  let data: ServiceProvider[] = [];

  tspProviders.data.forEach(serviceProvider => {
    let tspConfig = serviceProvider.tspConfigs.filter(tspConfig => tspConfig.configName === TSPConstant.SuppressBLMTSP);
    if ((tspConfig.length > 0 && tspConfig[0].configValue !== "true") || (tspConfig.length === 0)) {
      data.push(serviceProvider);
    }
  });

  tspProviders.data = data;

  return tspProviders;
}