import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { RolesService } from 'shared/services/roles.service';
import { AuthGuard } from './auth.guard';
import { BISACONSTANTS } from 'app/modules/bisa/bisa-constants';

export const ResourceRedirectGuard = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> => {
    const _rolesService = inject(RolesService);
    const _router = inject(Router);
    const resourceAcl = {
        resource: route.data.aclResource,
        accessLevel: route.data.aclAccessLevel,
        userType: route.data.aclUserType,
    };

    let accessGranted$: Observable<boolean>;

    var authGuard$ = AuthGuard(route, state) as Observable<boolean>;
    authGuard$.subscribe(result => {
        if (!result) {
            _router.navigate([BISACONSTANTS.bisaLogin], { queryParams: { redirect: route.routeConfig.path } });
        }
        else {
            const accessGranted$ = _rolesService.hasResourceAccess([resourceAcl]);
            accessGranted$.subscribe(result => {
                if (!result) {
                    _router.navigateByUrl('/unauthorized');
                }
            });

            return accessGranted$;
        }
    });

    return accessGranted$;
};
